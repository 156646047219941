import React from "react";
import style from "./index.module.less";
import useDefaultValue from "../../hook/useDefaultValue";
import useErrorBorder from "../../hook/useErrorBorder";

export default function Textarea({
  value = "",
  rows = 4,
  defaultValue = "",
  meta,
  ...props
}) {
  useDefaultValue({ defaultValue, onChange: props.onChange });
  const s = useErrorBorder(meta);

  return (
    <textarea
      className={style.textarea}
      value={value}
      rows={rows}
      style={{ ...s }}
      {...props}
    />
  );
}
