import React, { useState, useEffect } from "react";
import Form from "rc-field-form";
import md5 from "md5";
import axios from "../../../utils/request";
import Message from "rc-message";
import { replace } from "gatsby";
import queryString from "query-string";
import Cookies from "js-cookie";
import ErrorField from "../../../components/error-field";
import Textarea from "../../../components/input/textarea";
import Input from "../../../components/input";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import UploadGroup from "../../../components/upload/group";
import style from "./register.module.css";

export default ({ location }) => {
  const [form] = Form.useForm();
  let [count, setCount] = useState(60);
  const [smsButtonClicked, setSmsButtonClicked] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [firstStep, setFirstStep] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const parse = location.search ? queryString.parse(location.search) : 0;

  async function handleGetCode() {
    if (smsButtonClicked) return;
    if (
      form.getFieldsValue().account &&
      /^1[3-9]\d{9}$/.test(form.getFieldsValue().account)
    ) {
      setPhoneError(false);
      const { data } = await axios({
        method: "get",
        url: "/designer/code",
        params: {
          account: form.getFieldsValue().account,
        },
      });
      if (!data.success) {
        Message.warning({
          content: data.msg,
        });
        return;
      }
      clearInterval(timer);
      setSmsButtonClicked(true);
      const timer = setInterval(() => {
        setCount(count--);
        if (count === 0) {
          clearInterval(timer);
          setCount(60);
          setSmsButtonClicked(false);
        }
      }, 1000);
    } else {
      setPhoneError(true);
    }
  }

  async function toNextStep() {
    setPhoneError(false);
    const formData = {
      account: form.getFieldsValue().account,
      code: form.getFieldsValue().code,
    };
    try {
      const values = await form.validateFields();
      const { data } = await axios({
        method: "post",
        url: "/designer/code/valid",
        data: formData,
      });
      if (data.success) {
        setFirstStep({
          account: values.account,
          code: values.code,
          password: md5(values.password.trim()).toString(),
        });
        setNextStep(true);
      } else {
        Message.warning({
          content: data.msg,
        });
      }
    } catch (errorList) {}
  }

  useEffect(() => {
    if (parse?.edit) {
      async function getDetail() {
        const { data } = await axios({
          method: "get",
          url: "/designer/userinfo",
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        });
        // console.log(data);
        if (data) setUserInfo(data);
      }

      getDetail();
      setNextStep(true);
    }
  }, []);

  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader
        pathName={location.pathname}
        parseFlag={parse.edit}
        isApply={nextStep}
      ></PublisherHeader>
      <div className={style.applyStep}>
        <h2>
          {nextStep ? "申请信息" : "创建账户"}（{nextStep ? "2" : "1"}/2）
        </h2>
        <Form
          className={style.accountWrap}
          autoComplete="false"
          form={form}
          onFinishFailed={(error) => {
            const [first] = error.errorFields;
            if (Array.isArray(first?.name)) {
              const [firstname] = first?.name;
              const [node] = document.getElementsByName(firstname);
              if (node) {
                node.scrollIntoView({
                  block: "center",
                  behavior: "smooth",
                });
              }
            }
          }}
          onFinish={async (values) => {
            // console.log(values);
            setLoading(true);
            const dataForm = Object.assign(values, firstStep);
            const updateForm = Object.assign(values, {
              userId: userInfo.userId,
            });
            const reqMethod = parse?.edit ? "put" : "post";
            const action = parse?.edit
              ? "/designer/userinfo"
              : "/designer/register";
            try {
              const { data } = await axios({
                method: reqMethod,
                url: action,
                data: reqMethod === "put" ? updateForm : dataForm,
              });
              if (data.success) {
                if (!parse?.edit) {
                  Cookies.set("token", data.token, { expires: 1 });
                  Cookies.set("userId", data.userId, { expires: 1 });
                }
                replace("/designerPress/verify");
              } else {
                Message.warning({
                  content: data.msg,
                });
              }
            } catch (err) {
              console.error(err);
            }

            setLoading(false);
          }}
        >
          {!nextStep && (
            <React.Fragment>
              <div className={style.fillWrap}>
                <ErrorField
                  name="account"
                  rules={[
                    {
                      required: true,
                      message: "请输入手机号",
                      validateTrigger: "onSubmit",
                    },
                    {
                      validator(rule, value, callback) {
                        if (value) {
                          if (
                            !/^1[3-9]\d{9}$/.test(value) &&
                            !/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(
                              value
                            )
                          ) {
                            callback("请填写有效的手机号");
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input placeholder="请输入手机号" autoComplete="false" />
                </ErrorField>
                {phoneError && (
                  <span className={style.checkPhone}>请填写有效的手机号</span>
                )}
              </div>

              <div className={`${style.verifyWrap} ${style.fillWrap}`}>
                <ErrorField
                  name="code"
                  rules={[
                    { required: true, message: "输入验证码" },
                    {
                      validator(rule, value, callback) {
                        if (value) {
                          if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                            callback("请输入非空信息");
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input placeholder="输入验证码" autoComplete="false" />
                </ErrorField>
                <p className={style.getCode}>
                  {!smsButtonClicked ? (
                    <span onClick={handleGetCode}>获取验证码</span>
                  ) : (
                    <span style={{ color: "#6D7278" }}>
                      {count + "s重新获取"}
                    </span>
                  )}
                </p>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="password"
                  rules={[
                    { required: true, message: "请输入密码" },
                    {
                      validator(rule, value, callback) {
                        if (value) {
                          if (!/^[\w]{6,18}$/.test(value)) {
                            callback(
                              "密码长度在6~18之间，只能包含字母、数字和下划线"
                            );
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    placeholder="密码"
                    autoComplete="new-password"
                    type="password"
                  />
                </ErrorField>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="password2"
                  rules={[
                    {
                      required: true,
                      message: "请再次输入密码",
                      validateTrigger: "onSubmit",
                    },
                    (context) => ({
                      validator(rule, value, callback) {
                        const { password } = context.getFieldsValue();
                        if (password !== value) {
                          callback("密码不一致，请重新输入");
                        }
                        callback();
                      },
                      validateTrigger: "onChange",
                    }),
                  ]}
                >
                  <Input
                    placeholder="确认密码"
                    autoComplete="new-password"
                    type="password"
                  />
                </ErrorField>
              </div>
              <span onClick={toNextStep} className={style.buttonStyle}>
                继续
              </span>
            </React.Fragment>
          )}

          {nextStep && (
            <>
              <div className={style.fillWrap}>
                <ErrorField
                  name="username"
                  rules={[
                    { required: true, message: "请输入设计师姓名" },
                    {
                      validator(rule, value, callback) {
                        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                          callback("请输入非空信息");
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    placeholder="设计师姓名"
                    defaultValue={userInfo?.username}
                    name="username"
                  />
                </ErrorField>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="brand"
                  rules={[
                    { required: true, message: "请输入品牌名" },
                    {
                      validator(rule, value, callback) {
                        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                          callback("请输入非空信息");
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    placeholder="品牌名"
                    name="brand"
                    defaultValue={userInfo?.brand}
                  />
                </ErrorField>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="email"
                  rules={[
                    { required: true, message: "请输入邮箱" },
                    {
                      validator(rule, value, callback) {
                        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                          callback("请输入非空信息");
                        } else if (
                          !/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
                            value
                          )
                        ) {
                          callback("请输入有效的邮箱");
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    placeholder="邮箱"
                    defaultValue={userInfo?.email}
                  />
                </ErrorField>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="phone"
                  rules={[
                    { required: true, message: "请输入手机号" },
                    {
                      validator(rule, value, callback) {
                        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                          callback("请输入非空信息");
                        } else if (!/^1[3-9]\d{9}$/.test(value)) {
                          callback("请输入有效的手机号");
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    placeholder="手机号"
                    defaultValue={userInfo?.phone}
                    name="phone"
                  />
                </ErrorField>
              </div>

              <div className={style.fillWrap}>
                <ErrorField
                  name="wechat"
                  rules={[
                    { required: true, message: "请输入微信" },
                    {
                      validator(rule, value, callback) {
                        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                          callback("请输入非空信息");
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    placeholder="微信"
                    defaultValue={userInfo?.wechat}
                    name="wechat"
                  />
                </ErrorField>
              </div>

              <div className={style.introduction}>
                <ErrorField
                  label="设计师简介"
                  labelNewline
                  labelColor="#6D7278"
                  rules={[
                    {
                      required: true,
                      message: "请填写设计师简介",
                      validateTrigger: "onSubmit",
                    },
                    {
                      validator(rule, value, callback) {
                        if (value) {
                          if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
                            callback("请输入非空信息");
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="introduction"
                >
                  <Textarea defaultValue={userInfo?.introduction} />
                </ErrorField>
              </div>

              <div className={style.upload}>
                <span className={style.fileRemind}>
                  *每个文件请保持在 100Mb 以内
                </span>
                <ErrorField
                  label="附件上传（brandbook/lookbook）"
                  labelColor="#6D7278"
                  labelNewline
                  rules={[
                    {
                      validator(rule, value, callback) {
                        if (!value) {
                          callback("请上传附件");
                        }
                        if (value) {
                          if (value.length === 0) {
                            callback("请上传附件");
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="files"
                >
                  <UploadGroup defaultValue={userInfo?.files} />
                </ErrorField>
              </div>

              <button
                className={`${style.buttonStyle} ${loading ? style.grey : ""}`}
                disabled={loading}
              >
                {loading && (
                  <img
                    width="640"
                    height="360"
                    src={require("../../../img/icon_loading.png")}
                    alt="icy加载"
                  />
                )}
                {parse?.edit ? "确认修改" : "提交申请"}
              </button>
              <span className={style.remindInfo}>
                *请确保联系方式完整且有效，通过审核后工作人员将通过您填写的联系方式与您联系
              </span>
              {/* {!parse?.edit && (
                <span
                  className={style.upStep}
                  onClick={() => setNextStep(false)}
                >
                  上一步
                </span>
              )} */}
            </>
          )}
        </Form>
      </div>
      <div className={style.blackBottom}></div>
    </div>
  );
};
