import React, { useState, useEffect } from "react";
import { BaseUpload } from "./index";

export default function UploadGroup({ defaultValue = [], onChange, ...props }) {
  const [files, setFiles] = useState([
    {
      name: "",
      filetype: "",
      url: "",
    },
  ]);

  useEffect(() => {
    let v = {
      name: "",
      filetype: "",
      url: "",
    };
    if (defaultValue.length) {
      v = [...defaultValue, ...files];
      setFiles(v);
      onChange(v.filter((e) => e.url !== ""));
    }
  }, [defaultValue]);

  function handleChange(file) {
    return (item) => {
      let v = files;
      if (!item) {
        // delete
        v = files.filter((e) => e.url !== file.url);
      } else {
        v = [item, ...files];
      }

      setFiles(v);
      onChange(v.filter((e) => e.url !== ""));
    };
  }

  return files.map((item, index) => (
    <BaseUpload
      key={item.url.toString() + index}
      item={item}
      setItem={() => {}}
      {...props}
      onChange={handleChange(item)}
    />
  ));
}
